import { useRouter } from 'next/router';
import { FormattedMessage as T } from 'react-intl';
import { useGlobalConfig } from '../hooks/configContext';
import BusinessUnitWrapper from './BusinessUnitWrapper';
import m from './Navigation/messages';

import MarketWrapper from './MarketWrapper';
import Link from './RainbowLink/RainbowLink';
import Spacer from './Spacer/Spacer';

const LegalLinks = () => {
  const { locale } = useRouter();
  const { STOREFRONT_URL, BU_CONFIG, urls: storefrontUrls } = useGlobalConfig();
  const privacyUrl =
    BU_CONFIG.supported_locales.length > 1
      ? `${STOREFRONT_URL}/${locale}/legal/privacy/`
      : `${STOREFRONT_URL}/legal/privacy/`;

  const year = new Date().getFullYear();

  const SpacerIcon = () => <Spacer type="lilac" className="inline-block" />;

  const linkClassName = 'text-lilac-20 text-sm font-normal whitespace-nowrap';
  return (
    <div className="pt-4">
      <BusinessUnitWrapper includeUnits={['vivi-it']}>
        <>
          <Link
            href="https://vivienergia.imgix.net/uploads/2020/04/03120534/Privacy-Policy_Aprile-20201.pdf"
            target="_blank"
            className={linkClassName}
          >
            <T {...m.privacyTitle} />
          </Link>
          <SpacerIcon />
        </>
      </BusinessUnitWrapper>
      <BusinessUnitWrapper includeUnits={['otovo-br']}>
        <Link
          href="https://holu.com.br/docs/politica-de-privacidade"
          target="_blank"
          className={linkClassName}
        >
          <T {...m.privacyTitle} />
        </Link>
        <SpacerIcon />
      </BusinessUnitWrapper>
      <BusinessUnitWrapper onlyCentral excludeUnits={['otovo-br']}>
        <Link href={privacyUrl} locale={locale} className={linkClassName}>
          <T {...m.privacyTitle} />
        </Link>
        <SpacerIcon />
      </BusinessUnitWrapper>
      {storefrontUrls?.imprintUrl && (
        <>
          <Link
            href={storefrontUrls.imprintUrl}
            className={linkClassName}
            target="_blank"
          >
            <T {...m.imprintTitle} />
          </Link>
          <SpacerIcon />
        </>
      )}
      {storefrontUrls?.termsPageUrl && (
        <BusinessUnitWrapper excludeUnits={['vivi-it', 'oktavia-it']}>
          <Link
            href={storefrontUrls.termsPageUrl}
            target="_blank"
            className={linkClassName}
          >
            <T {...m.ourConditions} />
          </Link>
          <SpacerIcon />
        </BusinessUnitWrapper>
      )}

      {storefrontUrls?.leasingTermsPageUrl && (
        <BusinessUnitWrapper excludeUnits={['vivi-it']}>
          <Link
            href={storefrontUrls.leasingTermsPageUrl}
            target="_blank"
            className={linkClassName}
          >
            <T {...m.ourLeasingConditions} />
          </Link>
          <SpacerIcon />
        </BusinessUnitWrapper>
      )}

      <MarketWrapper markets={['es']}>
        <>
          <Link
            href={`${STOREFRONT_URL}/legal/terms-of-use/`}
            target="_blank"
            className={linkClassName}
          >
            <T {...m.termsOfUse} />
          </Link>
          <SpacerIcon />
        </>
      </MarketWrapper>
      <MarketWrapper markets={['gb']}>
        <>
          <Link
            href="https://otovoweb.s3.eu-central-1.amazonaws.com/PDF/otovo_uk_idd_2024.pdf"
            target="_blank"
            className={linkClassName}
          >
            Initial Disclosure Document
          </Link>
          <SpacerIcon />
          <Link
            href="https://otovoweb.s3.eu-central-1.amazonaws.com/PDF/otovo_uk_complaints_procedure_2024.pdf"
            target="_blank"
            className={linkClassName}
          >
            Complaints Policy
          </Link>
          <SpacerIcon />
        </>
      </MarketWrapper>
      <MarketWrapper markets={['at']}>
        <>
          <Link
            href="https://otovoweb.s3.eu-central-1.amazonaws.com/PDF/otovo_at_complaints_procedure_2024.pdf"
            target="_blank"
            className={linkClassName}
          >
            Beschwerdeabwicklung
          </Link>
          <SpacerIcon />
        </>
      </MarketWrapper>
      <MarketWrapper markets={['pt']}>
        <>
          <Link
            href="https://www.livroreclamacoes.pt/Inicio/"
            target="_blank"
            className={linkClassName}
          >
            Livro de reclamações
          </Link>
          <SpacerIcon />
        </>
      </MarketWrapper>
      <p className="inline-block text-sm text-lilac-40">
        © {BU_CONFIG.company_name} {BU_CONFIG.legal_suffix} {year}
      </p>
      <MarketWrapper markets={['gb']}>
        <div className="mt-6 space-y-4 text-xs text-lilac-20">
          <p>
            Disclaimer: Otovo Limited is an appointed representative of{' '}
            <Link href="https://www.productpartnerships.com/" target="_blank">
              {}
              Product Partnerships Limited
            </Link>{' '}
            (“PPL”) which is authorised and regulated by the Financial Conduct
            Authority. PPL’s FCA registration number is 626349 and its address
            is Second Floor, Atlas House, 31 King Street, Leeds LS1 2HL. PPL’s
            permitted business is to act as a Principal for a network of
            Appointed Representatives who arrange regulated credit facilities
            for customers who are purchasing goods from them.
          </p>
          <p>
            Otovo Limited acts as a credit broker not a lender. We will
            introduce you to a small panel of lenders who may be able to offer
            you finance facilities for your purchase. European Distributed
            Energy Assets Limited (FRN: 991365) who are part of the same group
            as Otovo Limited may be among these. We do not receive any
            commission for introducing customers to any lenders. You may be able
            to obtain finance for your purchase from other lenders and you are
            encouraged to seek alternative quotations. If you would like to know
            how we handle complaints, please ask for a copy of our complaints
            handling process. You can also find information about referring a
            complaint to the Financial Ombudsman Service (FOS) at{' '}
            <Link href="https://www.financial-ombudsman.org.uk/">
              financial-ombudsman.org.uk
            </Link>
            .
          </p>
          <p>
            Representative example: If you borrow £7,000 over 20 years (PV) and
            £3,000 over 10 years (Battery) at a Representative APR of 10% you
            would pay £91 per month in year 1, £114 in year 10 and £87 in year
            20. Total Charge for Credit £11,678 | Total Amount Payable £21,678
          </p>
        </div>
      </MarketWrapper>
    </div>
  );
};
export default LegalLinks;
